import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import Loader from './Loader';
import { useLoadScript, Autocomplete } from '@react-google-maps/api';

const libraries = ["places"];

const StepThree = forwardRef((props, ref) => {
    const { activeData, setMainData, setActiveStep, isMainLoader } = props

    let email_pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    // let phone_pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
    let phone_pattern = /^(\(\d{3}\)\s?\d{3}-\d{4}|\d{3}-\d{3}-\d{4}|\d{10})$/;
    let address_pattern = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\s,]{3,50}$/;

    const [data, setData] = useState({
        currentAddress: [],
        pastAddress: [],
        isPastAddressApplicable: true,
        circumstance: ""
    })
    const [addressModal, setAddressModal] = useState(false);
    const [currentModal, setCurrentModal] = useState("");
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isAddressSubmitted, setIsAddressSubmitted] = useState(false);
    const [editAddress, setEditAddress] = useState(null);
    const [yearCount, setYearCount] = useState(null)
    const [yearCountError, setYearCountError] = useState(false)
    const [addressData, setAddressData] = useState({
        residenceType: "Rent",
        movedInMonth: "January",
        movedInYear: new Date().getFullYear(),
        streetAddress: "",
        unit: "",
        city: "",
        state: "AK",
        zipcode: "",
        monthlyRent: "",
        monthlyMortgage: "",
        movingDescription: "",
        landLord: {
            fullName: "",
            email: "",
            hasNoEmail: false,
            phonenumber: ""
        },
        searchPropertyValue: ""
    })
    const [errors, setErrors] = useState({
        currentAddress: "",
        pastAddress: "",
        circumstance: ""
    });
    const [autocomplete, setAutocomplete] = useState(null);
    const [isProperty, setIsProperty] = useState(false)
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: "AIzaSyAYHIMXC8VXJcJ_2fsw4SxEAOXAp3xmmoE",
        libraries,
    });
    const [saveAddress, setSaveAddress] = useState("")
    const [saveAddressLoader, setSaveAddressLoader] = useState(false)
    const [isAnyError, setIsAnyError] = useState({ value: false, isCheck: false })

    useEffect(() => {
        if(activeData) {
            setData(activeData)
        }
    }, [])

    useEffect(() => {
        if(typeof saveAddress === "boolean") {
            saveAddressInfo()
        }
    }, [saveAddress])

    useEffect(() => {
        handleYearCount(data)
        if(isAnyError.isCheck) {
            if(
                !data.currentAddress.length ||
                // (data.isPastAddressApplicable && !data.pastAddress.length) ||
                (!data.circumstance && yearCount <= 5) ||
                (yearCount < 5 && data.circumstance === "")
            ) setIsAnyError({ value: true, isCheck: true })
            else setIsAnyError({ value: false, isCheck: true })
        }
    }, [data])

    useImperativeHandle(ref, () => {
        return getData()
    }, [data]);

    const saveAddressInfo = async () => {
        await setMainData(3, data)
        setIsProperty(false)
        setAddressModal(false)
        setSaveAddressLoader(false)
        setAddressData({
            residenceType: "Rent",
            movedInMonth: "January",
            movedInYear: new Date().getFullYear(),
            streetAddress: "",
            unit: "",
            city: "",
            state: "AK",
            zipcode: "",
            monthlyRent: "",
            monthlyMortgage: "",
            movingDescription: "",
            landLord: {
                fullName: "",
                email: "",
                hasNoEmail: false,
                phonenumber: ""
            },
            searchPropertyValue: ""
        })
        setEditAddress(null)
        setIsAddressSubmitted(false)
    }

    const getData = () => {
        let dataObj = {
            step: 3,
            stepData: data,
        }
        return dataObj;
    };

    const handleYearCount = (data, update) => {
        let yearList = []
        let countYear = 0;
        for(let i = 0; i < data.currentAddress.length; i++) {
            let obj = { month: data.currentAddress[i].movedInMonth, year: data.currentAddress[i].movedInYear }
            yearList = [...yearList, obj]
        }
        for(let i = 0; i < data.pastAddress.length; i++) {
            let obj = { month: data.pastAddress[i].movedInMonth, year: data.pastAddress[i].movedInYear }
            yearList = [...yearList, obj]
        }

        const yearDifferences = calculateYearDifference(yearList);
        for(let i = 0; i < yearDifferences.length; i++) {
            let mainYearLists = yearDifferences[i] ? yearDifferences[i] : 0
            countYear = countYear + mainYearLists
        }
        setYearCount(countYear)
        if(isSubmitted || data.currentAddress?.[0]?.movedInYear) {
            if(countYear >= 5) {
                if(data.currentAddress.length && update) {
                    // setData((prev) => ({ ...prev, isPastAddressApplicable: false }))
                }
                setYearCountError(false)
            } else {
                setYearCountError(true)
            }
        }
    }

    function calculateYearDifference(yearlist) {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1;
        const differences = yearlist.map(item => {
            const itemYear = parseInt(item.year);
            const itemMonth = new Date(`${item.month} 1, ${itemYear}`);
            const yearDifference = currentYear - itemYear;
            const monthDifference = currentMonth - (itemMonth.getMonth() + 1);
            const mainYear = yearDifference + (monthDifference / 12)
            return mainYear;
        });
        return differences;
    }


    const handleAddressSave = (e) => {
        e.preventDefault()
        setIsAddressSubmitted(true)
        if((!addressData.streetAddress || !address_pattern.test(addressData.streetAddress)) ||
            !addressData.city || (!addressData.searchPropertyValue || !isProperty) ||
            (!addressData.zipcode || addressData.zipcode.toString().length !== 5) ||
            (addressData.residenceType === "Rent" && (!addressData.monthlyRent)) ||
            (addressData.residenceType === "Own" && (!addressData.monthlyMortgage)) ||
            (addressData.residenceType !== "Own" && (!addressData.landLord.fullName ||
                ((!addressData.landLord.email || !email_pattern.test(addressData.landLord.email)) && !addressData.landLord.hasNoEmail) ||
                (!addressData.landLord.phonenumber || !phone_pattern.test(addressData.landLord.phonenumber))))
        ) {
            return
        } else {
            setSaveAddressLoader(true)
            if(currentModal === "Current") {
                setErrors({ ...errors, currentAddress: "" })
            } else if(currentModal === "Past") {
                setErrors({ ...errors, pastAddress: "" })
            }
        }
        if(editAddress !== null) {
            if(currentModal === "Current") {
                let updatedData = [...data.currentAddress]
                updatedData = updatedData.map((item, index) => {
                    if(index === editAddress) {
                        item = addressData
                    }
                    return item
                })
                setData({ ...data, currentAddress: updatedData })
            } else if(currentModal === "Past") {
                let updatedData = [...data.pastAddress]
                updatedData = updatedData.map((item, index) => {
                    if(index === editAddress) {
                        item = addressData
                    }
                    return item
                })
                setData({ ...data, pastAddress: updatedData })
            }
        } else {
            if(currentModal === "Current") {
                setData({ ...data, currentAddress: [...data.currentAddress, addressData] })
            } else if(currentModal === "Past") {
                setData({ ...data, pastAddress: [...data.pastAddress, addressData] })
            }
        }

        handleYearCount({
            currentAddress: [...data.currentAddress, currentModal === "Current" &&  addressData],
            pastAddress: [...data.pastAddress, currentModal === "Past" && addressData],
            isPastAddressApplicable: data.isPastAddressApplicable,
            circumstance: data.circumstance
        }, true)

        setSaveAddress(!saveAddress)
    }

    const handleNext = async () => {
        setIsSubmitted(true)
        let error = {};
        let isError = false;
        if(!data.currentAddress.length) {
            error = { ...error, currentAddress: "minimum 1 address is required" }
            isError = true
        }
        // if(data.isPastAddressApplicable && !data.pastAddress.length) {
        //     error = { ...error, pastAddress: "minimum 1 address is required" }
        //     isError = true
        // }
        if(!data.circumstance && (yearCount !== null && yearCount <= 5)) {
            error = { ...error, circumstance: "circumstance is required" }
            isError = true
        }
        if(yearCount >= 5){
            error = { ...error, pastAddress: "" }
            isError = false
        }
        if(isError) {
            setErrors(error)
            setIsAnyError({ value: true, isCheck: true })
            return
        }

        if(yearCount >= 5 || data.circumstance !== "") {
            setYearCountError(false)
            if(yearCount >= 5) await setMainData(3, { ...data, circumstance: "" })
            else await setMainData(3, data)
            setActiveStep(4)
        } else {
            setYearCountError(true)
            setIsAnyError({ value: true, isCheck: true })
        }
    }

    const handleChange = (value) => {
        if(value && isSubmitted) {
            setErrors({ ...errors, pastAddress: "" })
        }
        if(yearCount >= 5){
            setErrors({ ...errors, pastAddress: "" })
        }
        setData({ ...data, isPastAddressApplicable: !value })
    }

    const handleCircumstanceChange = (event) => {
        const { name, value } = event.target;
        let error = { ...errors }
        if(isSubmitted) {
            if(yearCountError) {
                if(name === "circumstance") {
                    if(!value) {
                        error = { ...error, circumstance: "circumstance is required" }
                    } else {
                        error = { ...error, circumstance: "" }
                    }
                }
            }
        }
        setErrors(error)
        setData({ ...data, [name]: value })
    }

    const handleEdit = (modal, item, i) => {
        setIsProperty(true)
        setAddressData(item)
        setCurrentModal(modal)
        setAddressModal(true)
        setEditAddress(i)
    }

    const handleDelete = (key, index) => {
        setData({ ...data, [key]: data[key].filter((item, i) => index !== i) })
    }

    const onLoad = (autocompleteInstance) => {
        setAutocomplete(autocompleteInstance);
    };

    const onPlaceChanged = () => {
        if(autocomplete !== null) {
            const place = autocomplete.getPlace();
            const addressComponents = place.address_components;
            let newAddress = {
                street: '',
                city: '',
                state: '',
                zip: '',
            };
            addressComponents.forEach(component => {
                const types = component.types;
                if(types.includes("street_number")) {
                    newAddress.street = component.long_name + ' ' + newAddress.street;
                }
                if(types.includes("route")) {
                    newAddress.street += component.long_name;
                }
                if(types.includes("locality")) {
                    newAddress.city = component.long_name;
                }
                if(types.includes("administrative_area_level_1")) {
                    newAddress.state = component.short_name;
                }
                if(types.includes("postal_code")) {
                    newAddress.zip = component.long_name;
                }
            });
            setAddressData({
                ...addressData,
                searchPropertyValue: place?.formatted_address,
                streetAddress: newAddress.street,
                city: newAddress.city,
                state: newAddress.state,
                zipcode: newAddress.zip,
            })
            setIsProperty(true)
        } else {
            console.log("Autocomplete is not loaded yet!");
        }
    };

    if(loadError) return <section className='step-section step-3'>Loading..</section>;
    if(!isLoaded) return <section className='step-section step-3'>Loading...</section>;

    return (
        <>
            {/* Add Address Modal Start */}
            <Modal
                show={addressModal}
                onHide={() => {
                    setAddressModal(false)
                    setSaveAddressLoader(false)
                }}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton className='border-0'></Modal.Header>
                <Modal.Body>
                    <h3 className='step-title mb-0'>{editAddress !== null ? "Edit" : "Add"} {currentModal} Address</h3>
                    <Form className='mt-4' onSubmit={handleAddressSave}>
                        <Form.Label>Residence Type</Form.Label>
                        <div className="tab-style">
                            <Form.Check
                                label="Rent"
                                name="residence"
                                type="radio"
                                id={`residence_rent`}
                                checked={addressData.residenceType === 'Rent'}
                                onChange={(e) => setAddressData({ ...addressData, residenceType: e.target.labels[0].innerText })}
                            />
                            <Form.Check
                                label="Own"
                                name="residence"
                                type="radio"
                                id={`residence_own`}
                                checked={addressData.residenceType === 'Own'}
                                onChange={(e) => setAddressData({ ...addressData, residenceType: e.target.labels[0].innerText })}
                            />
                            <Form.Check
                                label="Other"
                                name="residence"
                                type="radio"
                                id={`residence_other`}
                                checked={addressData.residenceType === 'Other'}
                                onChange={(e) => setAddressData({ ...addressData, residenceType: e.target.labels[0].innerText })}
                            />
                        </div>
                        <Row className="mt-3">
                            <Form.Group as={Col} md="5" className='mb-3 col-6'>
                                <Form.Label>Month Moved In</Form.Label>
                                <Form.Select
                                    value={addressData.movedInMonth}
                                    onChange={(e) => setAddressData({ ...addressData, movedInMonth: e.target.value })}
                                >
                                    <option value="January">January</option>
                                    <option value="February">February</option>
                                    <option value="March">March</option>
                                    <option value="April">April</option>
                                    <option value="May">May</option>
                                    <option value="June">June</option>
                                    <option value="July">July</option>
                                    <option value="August">August</option>
                                    <option value="September">September</option>
                                    <option value="October">October</option>
                                    <option value="November">November</option>
                                    <option value="December">December</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col} md="4" className='mb-3 col-6'>
                                <Form.Label>Year Moved In</Form.Label>
                                <Form.Select
                                    value={addressData.movedInYear}
                                    onChange={(e) => setAddressData({ ...addressData, movedInYear: e.target.value })}
                                >
                                    {
                                        Array.from(Array(50)).map((item, index) => <option value={new Date().getFullYear() - index} key={index}>{new Date().getFullYear() - index}</option>)
                                    }
                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col} md="12" className='mb-3 col-12'>
                                <Form.Label>Search property address</Form.Label>
                                <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                                    <Form.Control
                                        type="text"
                                        placeholder='Search property'
                                        value={addressData.searchPropertyValue}
                                        onChange={(e) => setAddressData({ ...addressData, searchPropertyValue: e.target.value })}
                                        isInvalid={isAddressSubmitted && (!addressData.searchPropertyValue || !isProperty)}
                                    />
                                </Autocomplete>
                                {(isAddressSubmitted && (!addressData.searchPropertyValue || !isProperty)) && <Form.Control.Feedback type="invalid" className="text-danger error-msg d-block">Property address required</Form.Control.Feedback>}
                            </Form.Group>
                            {isProperty &&
                                <>
                                    <Form.Group as={Col} md="8" className='mb-3 col-8'>
                                        <Form.Label>Street Address</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="streetAddress"
                                            value={addressData.streetAddress}
                                            onChange={(e) => setAddressData({ ...addressData, streetAddress: e.target.value })}
                                            isInvalid={isAddressSubmitted && (!addressData.streetAddress || !address_pattern.test(addressData.streetAddress))}
                                        />
                                        <Form.Control.Feedback type="invalid" className="text-danger error-msg">{"Must be 3-50 characters, contain at least one number, and one letter, and cannot contain the following characters: * < > = &."}</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" className='mb-3 col-4'>
                                        <Form.Label>Unit (Optional)</Form.Label>
                                        <Form.Control
                                            value={addressData.unit}
                                            onChange={(e) => setAddressData({ ...addressData, unit: e.target.value })}
                                            type="text"
                                            name="unit"
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="6" className='mb-3 col-6'>
                                        <Form.Label>City</Form.Label>
                                        <Form.Control
                                            value={addressData.city}
                                            onChange={(e) => setAddressData({ ...addressData, city: e.target.value })}
                                            type="text"
                                            name="city"
                                            isInvalid={isAddressSubmitted && !addressData.city}
                                        />
                                        <Form.Control.Feedback type="invalid" className="text-danger error-msg">city is required</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="3" className='mb-3 col-3'>
                                        <Form.Label>State</Form.Label>
                                        <Form.Select
                                            value={addressData.state}
                                            onChange={(e) => setAddressData({ ...addressData, state: e.target.value })}
                                        >
                                            <option value="AK">AK</option>
                                            <option value="AL">AL</option>
                                            <option value="AR">AR</option>
                                            <option value="AZ">AZ</option>
                                            <option value="CA">CA</option>
                                            <option value="CO">CO</option>
                                            <option value="CT">CT</option>
                                            <option value="DC">DC</option>
                                            <option value="DE">DE</option>
                                            <option value="FL">FL</option>
                                            <option value="GA">GA</option>
                                            <option value="GU">GU</option>
                                            <option value="HI">HI</option>
                                            <option value="IA">IA</option>
                                            <option value="ID">ID</option>
                                            <option value="IL">IL</option>
                                            <option value="IN">IN</option>
                                            <option value="KS">KS</option>
                                            <option value="KY">KY</option>
                                            <option value="LA">LA</option>
                                            <option value="MA">MA</option>
                                            <option value="MD">MD</option>
                                            <option value="ME">ME</option>
                                            <option value="MI">MI</option>
                                            <option value="MN">MN</option>
                                            <option value="MO">MO</option>
                                            <option value="MS">MS</option>
                                            <option value="MT">MT</option>
                                            <option value="NC">NC</option>
                                            <option value="ND">ND</option>
                                            <option value="NE">NE</option>
                                            <option value="NH">NH</option>
                                            <option value="NJ">NJ</option>
                                            <option value="NM">NM</option>
                                            <option value="NV">NV</option>
                                            <option value="NY">NY</option>
                                            <option value="OH">OH</option>
                                            <option value="OK">OK</option>
                                            <option value="OR">OR</option>
                                            <option value="PA">PA</option>
                                            <option value="PR">PR</option>
                                            <option value="RI">RI</option>
                                            <option value="SC">SC</option>
                                            <option value="SD">SD</option>
                                            <option value="TN">TN</option>
                                            <option value="TX">TX</option>
                                            <option value="UT">UT</option>
                                            <option value="VA">VA</option>
                                            <option value="VI">VI</option>
                                            <option value="VT">VT</option>
                                            <option value="WA">WA</option>
                                            <option value="WI">WI</option>
                                            <option value="WV">WV</option>
                                            <option value="WY">WY</option>
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group as={Col} md="3" className='mb-3 col-3'>
                                        <Form.Label>Zip code</Form.Label>
                                        <Form.Control
                                            type="number"
                                            name="zipCode"
                                            value={addressData.zipcode}
                                            onChange={(e) => setAddressData({ ...addressData, zipcode: e.target.value })}
                                            isInvalid={isAddressSubmitted && (!addressData.zipcode || addressData.zipcode.toString().length !== 5)}
                                        />
                                        <Form.Control.Feedback type="invalid" className="text-danger error-msg">{!addressData.zipcode ? "zipcode is required" : addressData.zipcode.toString().length !== 5 ? "zipcode is invaild" : ""}</Form.Control.Feedback>
                                    </Form.Group>
                                </>
                            }
                            {
                                addressData.residenceType === 'Rent' &&
                                <Form.Group as={Col} md="6" className='mb-3 col-8 price-input'>
                                    <Form.Label>Monthly Rent</Form.Label>
                                    <Form.Control
                                        value={addressData.monthlyRent}
                                        onChange={(e) => setAddressData({ ...addressData, monthlyRent: e.target.value })}
                                        type="number"
                                        name="monthlyRent"
                                        isInvalid={isAddressSubmitted && (!addressData.monthlyRent)}
                                    />
                                    <Form.Control.Feedback type="invalid" className="text-danger error-msg">Monthly Rent is required</Form.Control.Feedback>
                                </Form.Group>
                            }
                            {
                                addressData.residenceType === 'Own' &&
                                <Form.Group as={Col} md="6" className='mb-3 col-8 price-input'>
                                    <Form.Label>Monthly Mortgage</Form.Label>
                                    <Form.Control
                                        value={addressData.monthlyMortgage}
                                        onChange={(e) => setAddressData({ ...addressData, monthlyMortgage: e.target.value })}
                                        type="number"
                                        name="monthlyMortgage"
                                        isInvalid={isAddressSubmitted && (!addressData.monthlyMortgage)}
                                    />
                                    <Form.Control.Feedback type="invalid" className="text-danger error-msg">Monthly Mortgage is required</Form.Control.Feedback>
                                </Form.Group>
                            }
                            <Form.Group as={Col} md="12" className='mb-3'>
                                <Form.Label>Why are you moving? (Optional)</Form.Label>
                                <Form.Control
                                    value={addressData.movingDescription}
                                    onChange={(e) => setAddressData({ ...addressData, movingDescription: e.target.value })}
                                    as="textarea"
                                    name="moving"
                                    style={{ height: '76px' }}
                                />
                            </Form.Group>
                            {
                                (addressData.residenceType === 'Rent' || addressData.residenceType === 'Other') &&
                                <>
                                    <h5 className='step-small-title mb-3 mt-2'>Landlord Contact Info</h5>
                                    <Form.Group as={Col} md="12" className='mb-3'>
                                        <Form.Label>Full Name</Form.Label>
                                        <Form.Control
                                            value={addressData.landLord.fullName}
                                            onChange={(e) => setAddressData({ ...addressData, landLord: { ...addressData.landLord, fullName: e.target.value } })}
                                            type="text"
                                            name="fullName"
                                            isInvalid={isAddressSubmitted && !addressData.landLord.fullName}
                                        />
                                        <Form.Control.Feedback type="invalid" className="text-danger error-msg">Full name is required</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" className='mb-3'>
                                        <Form.Label>Email Address</Form.Label>
                                        <Form.Control
                                            value={addressData.landLord.email}
                                            onChange={(e) => setAddressData({ ...addressData, landLord: { ...addressData.landLord, email: e.target.value } })}
                                            type="email"
                                            disabled={addressData.landLord.hasNoEmail}
                                            name="emailAddress"
                                            isInvalid={isAddressSubmitted && ((!addressData.landLord.email || !email_pattern.test(addressData.landLord.email)) && !addressData.landLord.hasNoEmail)}
                                        />
                                        <Form.Control.Feedback type="invalid" className="text-danger error-msg">{!addressData.landLord.email ? "Email is required" : !email_pattern.test(addressData.landLord.email) ? "Email is invaild" : ""}</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" className='mb-3'>
                                        <Form.Check
                                            checked={addressData.landLord.hasNoEmail}
                                            onChange={(e) => setAddressData({ ...addressData, landLord: { ...addressData.landLord, hasNoEmail: e.target.checked, email: e.target.checked ? "" : addressData.landLord.email } })}
                                            type='checkbox'
                                            id="hasNoEmail"
                                            label="I don't know my landlord's email"
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="6" className='mb-3'>
                                        <Form.Label>Phone Number</Form.Label>
                                        <Form.Control
                                            value={addressData.landLord.phonenumber}
                                            onChange={(e) => {
                                                var x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                                                const filterValue = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
                                                setAddressData({ ...addressData, landLord: { ...addressData.landLord, phonenumber: filterValue } })
                                            }}
                                            type="text"
                                            name="phoneNumber"
                                            placeholder="(---) --- - ----"
                                            isInvalid={isAddressSubmitted && (!addressData.landLord.phonenumber || !phone_pattern.test(addressData.landLord.phonenumber))}
                                        />
                                        <Form.Control.Feedback type="invalid" className="text-danger error-msg">{!addressData.landLord.phonenumber ? "Phone number is required" : !phone_pattern.test(addressData.landLord.phonenumber) ? "Phone number is invaild" : ""}</Form.Control.Feedback>
                                    </Form.Group>
                                </>
                            }
                        </Row>

                        <Button type="submit" className='w-100 text-uppercase step-button d-block mx-auto mt-4' disabled={saveAddressLoader}>{saveAddressLoader ? <Loader /> : `${editAddress !== null ? "Edit" : "Add"} ${currentModal} ADDRESS`}</Button>
                    </Form>
                </Modal.Body>
            </Modal >
            {/* Add Address Modal end */}
            < section className='step-section step-3' >
                <p><b>Property: &nbsp;</b> {localStorage.getItem('propertyName')}</p>
                <h3 className='step-title mb-1'>Residential History</h3>
                <p>We require at least <b>5 years</b> of rental history.</p>
                <h3 className='step-small-title mb-2'>Current Address</h3>
                {
                    data.currentAddress.map((item, index) =>
                        <div key={index} className='applying-item'>
                            <div>
                                <h4 className='application-full-name d-flex align-items-start justify-content-between'>
                                    <div className='application-name'>{item.streetAddress}{item.unit ? " " + item.unit + "," : ""} {item.city}, {item.state} {item.zipcode}</div>
                                </h4>
                                <p className='mb-1'><span className='box-sub-title'>Type: </span>{item.residenceType}</p>
                                <p className='mb-1'><span className='box-sub-title'>Moved In: </span>{item.movedInMonth} {item.movedInYear}</p>
                                {item.residenceType === "Rent" && item.monthlyRent && <p className='mb-1'><span className='box-sub-title'>Monthly Rent: </span>${item.monthlyRent}</p>}
                                {item.residenceType === "Own" && item.monthlyMortgage && <p className='mb-1'><span className='box-sub-title'>Monthly Rent: </span>${item.monthlyMortgage}</p>}
                                {item.movingDescription && <p className='mb-1'><span className='box-sub-title'>Why are you moving: </span>{item.movingDescription}</p>}
                                {
                                    item.residenceType !== "Own" && <p className='mb-1'>
                                        <span className='box-sub-title'>Landlord:</span>
                                        <span className='d-block'>{item.landLord.fullName}</span>
                                        {item.landLord.email && <span className='d-block'>{item.landLord.email}</span>}
                                        <span className='d-block'>{item.landLord.phonenumber}</span>
                                    </p>
                                }
                                <div className='d-flex justify-content-between mt-3'>
                                    <button onClick={() => handleEdit("Current", item, index)} type='button' className='action-btn'>
                                        <svg width="21" height="21" color="#033A6D" viewBox="0 0 21 21" fill="#033A6D"><path d="M18.458.7l1.846 1.845a2.394 2.394 0 010 3.382l-.602.601-.012.016a.664.664 0 01-.015.014l-1.212 1.211-.015.017a.664.664 0 01-.015.015L6.96 19.268a.091.091 0 01-.01.012l-.214.212a.652.652 0 01-.312.174l-5.622 1.32a.652.652 0 01-.784-.785l1.319-5.621a.652.652 0 01.174-.313l.207-.208.01-.011a.665.665 0 01.012-.011L14.454 1.32l.01-.009.611-.612a2.393 2.393 0 013.383 0zM2.422 15.664l-.894 3.811 3.811-.895-2.917-2.916zM13.689 3.929L3.113 14.509l3.38 3.381 10.57-10.571-3.374-3.39zm1.233-1.231l-.311.309 3.375 3.389.318-.316-3.382-3.382zm1.075-1.075l-.153.153 3.382 3.382.155-.153a1.09 1.09 0 000-1.538l-1.845-1.844a1.089 1.089 0 00-1.539 0z"></path></svg>
                                        Edit
                                    </button>
                                    <button onClick={() => handleDelete("currentAddress", index)} type='button' className='action-btn'>
                                        <svg width="18" height="20" color="#033A6D" viewBox="0 0 18 20" fill="#033A6D"><path d="M12.445 0c.357 0 .646.274.646.612l-.001 1.836 4.317.001a.6.6 0 01.585.513l.008.1a.603.603 0 01-.593.611h-1.044v15.715a.622.622 0 01-.63.612H2.267a.622.622 0 01-.632-.612V3.673H.593a.6.6 0 01-.585-.512L0 3.06c0-.338.266-.612.593-.612l4.316-.001V.612C4.91.274 5.2 0 5.555 0h6.89zm2.656 3.673H2.9v15.103H15.1V3.673zm-9.456 1.94c.34 0 .614.273.614.611v9.796a.613.613 0 01-1.227 0V6.224c0-.338.275-.612.613-.612zm3.273 0c.34 0 .614.273.614.611v9.796a.613.613 0 01-1.227 0V6.224c0-.338.274-.612.613-.612zm3.273 0c.339 0 .614.273.614.611v9.796a.613.613 0 01-1.228 0V6.224c0-.338.275-.612.614-.612zm-.392-4.389H6.201V2.45h5.598V1.224z"></path></svg>
                                        Delete
                                    </button>
                                </div>
                            </div>
                        </div>
                    )
                }
                {data?.currentAddress?.length < 1 ?
                    <button type='button' className='action-btn' onClick={() => { setAddressModal(true); setCurrentModal("Current") }}>
                        <svg className="kzsFVxnNUwoBGhZVibsb" width="18" height="18" viewBox="0 0 18 18"><path d="M9 16.7C13.3 16.7 16.7 13.3 16.7 9 16.7 4.7 13.3 1.3 9 1.3 4.7 1.3 1.3 4.7 1.3 9 1.3 13.3 4.7 16.7 9 16.7ZM9 18C4 18 0 14 0 9 0 4 4 0 9 0 14 0 18 4 18 9 18 14 14 18 9 18ZM9.8 8.2L12.2 8.2C12.6 8.2 13 8.6 13 9 13 9.4 12.6 9.8 12.2 9.8L9.8 9.8 9.8 12.2C9.8 12.6 9.4 13 9 13 8.6 13 8.2 12.6 8.2 12.2L8.2 9.8 5.8 9.8C5.4 9.8 5 9.4 5 9 5 8.6 5.4 8.2 5.8 8.2L8.2 8.2 8.2 5.8C8.2 5.4 8.6 5 9 5 9.4 5 9.8 5.4 9.8 5.8L9.8 8.2Z"></path></svg>
                        Add Current Address
                    </button> : ''}
                {isSubmitted && errors.currentAddress && <div className="text-danger error-msg">{errors.currentAddress}</div>}
                <h4 className='step-small-title mb-2 mt-4'>Past Addresses</h4>
                {
                    data.pastAddress.map((item, index) =>
                        <div key={index} className='applying-item'>
                            <div>
                                <h4 className='application-full-name d-flex align-items-start justify-content-between'>
                                    <div className='application-name'>{item.streetAddress}{item.unit ? " " + item.unit + "," : ""} {item.city}, {item.state} {item.zipcode}</div>
                                </h4>
                                <p className='mb-1'><span className='box-sub-title'>Type: </span>{item.residenceType}</p>
                                <p className='mb-1'><span className='box-sub-title'>Moved In: </span>{item.movedInMonth} {item.movedInYear}</p>
                                {item.residenceType === "Rent" && item.monthlyRent && <p className='mb-1'><span className='box-sub-title'>Monthly Rent: </span>${item.monthlyRent}</p>}
                                {item.residenceType === "Own" && item.monthlyMortgage && <p className='mb-1'><span className='box-sub-title'>Monthly Rent: </span>${item.monthlyMortgage}</p>}
                                {item.movingDescription && <p className='mb-1'><span className='box-sub-title'>Why are you moving: </span>{item.movingDescription}</p>}
                                {
                                    item.residenceType !== "Own" && <p className='mb-1'>
                                        <span className='box-sub-title'>Landlord:</span>
                                        <span className='d-block'>{item.landLord.fullName}</span>
                                        {item.landLord.email && <span className='d-block'>{item.landLord.email}</span>}
                                        <span className='d-block'>{item.landLord.phonenumber}</span>
                                    </p>
                                }
                                <div className='d-flex justify-content-between mt-3'>
                                    <button onClick={() => handleEdit("Past", item, index)} type='button' className='action-btn'>
                                        <svg width="21" height="21" color="#033A6D" viewBox="0 0 21 21" fill="#033A6D"><path d="M18.458.7l1.846 1.845a2.394 2.394 0 010 3.382l-.602.601-.012.016a.664.664 0 01-.015.014l-1.212 1.211-.015.017a.664.664 0 01-.015.015L6.96 19.268a.091.091 0 01-.01.012l-.214.212a.652.652 0 01-.312.174l-5.622 1.32a.652.652 0 01-.784-.785l1.319-5.621a.652.652 0 01.174-.313l.207-.208.01-.011a.665.665 0 01.012-.011L14.454 1.32l.01-.009.611-.612a2.393 2.393 0 013.383 0zM2.422 15.664l-.894 3.811 3.811-.895-2.917-2.916zM13.689 3.929L3.113 14.509l3.38 3.381 10.57-10.571-3.374-3.39zm1.233-1.231l-.311.309 3.375 3.389.318-.316-3.382-3.382zm1.075-1.075l-.153.153 3.382 3.382.155-.153a1.09 1.09 0 000-1.538l-1.845-1.844a1.089 1.089 0 00-1.539 0z"></path></svg>
                                        Edit
                                    </button>
                                    <button onClick={() => handleDelete("pastAddress", index)} type='button' className='action-btn'>
                                        <svg width="18" height="20" color="#033A6D" viewBox="0 0 18 20" fill="#033A6D"><path d="M12.445 0c.357 0 .646.274.646.612l-.001 1.836 4.317.001a.6.6 0 01.585.513l.008.1a.603.603 0 01-.593.611h-1.044v15.715a.622.622 0 01-.63.612H2.267a.622.622 0 01-.632-.612V3.673H.593a.6.6 0 01-.585-.512L0 3.06c0-.338.266-.612.593-.612l4.316-.001V.612C4.91.274 5.2 0 5.555 0h6.89zm2.656 3.673H2.9v15.103H15.1V3.673zm-9.456 1.94c.34 0 .614.273.614.611v9.796a.613.613 0 01-1.227 0V6.224c0-.338.275-.612.613-.612zm3.273 0c.34 0 .614.273.614.611v9.796a.613.613 0 01-1.227 0V6.224c0-.338.274-.612.613-.612zm3.273 0c.339 0 .614.273.614.611v9.796a.613.613 0 01-1.228 0V6.224c0-.338.275-.612.614-.612zm-.392-4.389H6.201V2.45h5.598V1.224z"></path></svg>
                                        Delete
                                    </button>
                                </div>
                            </div>
                        </div>
                    )
                }
                {
                    !data.pastAddress.length && <Form.Check
                        type='checkbox'
                        id="not-applicable"
                        label="Not applicable"
                        checked={!data.isPastAddressApplicable}
                        onChange={(e) => handleChange(e.target.checked)}
                    />
                }
                {
                    data.isPastAddressApplicable && <button type='button' className='action-btn mt-3' onClick={() => { setAddressModal(true); setCurrentModal("Past") }}>
                        <svg className="kzsFVxnNUwoBGhZVibsb" width="18" height="18" viewBox="0 0 18 18"><path d="M9 16.7C13.3 16.7 16.7 13.3 16.7 9 16.7 4.7 13.3 1.3 9 1.3 4.7 1.3 1.3 4.7 1.3 9 1.3 13.3 4.7 16.7 9 16.7ZM9 18C4 18 0 14 0 9 0 4 4 0 9 0 14 0 18 4 18 9 18 14 14 18 9 18ZM9.8 8.2L12.2 8.2C12.6 8.2 13 8.6 13 9 13 9.4 12.6 9.8 12.2 9.8L9.8 9.8 9.8 12.2C9.8 12.6 9.4 13 9 13 8.6 13 8.2 12.6 8.2 12.2L8.2 9.8 5.8 9.8C5.4 9.8 5 9.4 5 9 5 8.6 5.4 8.2 5.8 8.2L8.2 8.2 8.2 5.8C8.2 5.4 8.6 5 9 5 9.4 5 9.8 5.4 9.8 5.8L9.8 8.2Z"></path></svg>
                        Add Past Address
                    </button>
                }
                {isSubmitted && errors.pastAddress && <div className="text-danger error-msg">{errors.pastAddress}</div>}
                {yearCountError && <Form.Group as={Col} md="12" className='my-3' controlId="circumstance">
                    <Form.Label>Explain if less than 5 years of residential history</Form.Label>
                    <Form.Control
                        type="text"
                        name="circumstance"
                        value={data.circumstance}
                        onChange={handleCircumstanceChange}
                        isInvalid={errors.circumstance}
                    />
                    {/* <Form.Control.Feedback type="invalid">{errors.circumstance}</Form.Control.Feedback> */}
                </Form.Group>}
                {yearCountError && !data.circumstance && <Form.Control.Feedback type="invalid" className="text-danger d-block text-center mt-3 only-error-msg">Need at least 5 years worth of residential history or describe the reason.</Form.Control.Feedback>}
                <Button type="button" className='text-uppercase step-button d-block mx-auto mt-4' onClick={handleNext} disabled={isMainLoader}>{isMainLoader ? <Loader /> : 'Next'}</Button>
                {isAnyError.value && <p className="text-danger error-msg m-2 text-center">All fields not completed</p>}
            </section >
        </>
    )
});

export default StepThree;