import React, { useState, useEffect, useRef } from 'react'
import { Button, Form, Modal, ProgressBar } from 'react-bootstrap';
import { API, Auth } from 'aws-amplify';
import SiteLogo from '../components/SiteLogo';
import MainStep from '../components/MainStep';
import StepOne from '../components/StepOne';
import StepTwo from '../components/StepTwo';
import StepThree from '../components/StepThree';
import StepFour from '../components/StepFour';
import StepFive from '../components/StepFive';
import StepSix from '../components/StepSix';
import StepSeven from '../components/StepSeven';
import StepEight from '../components/StepEight';
import StepNine from '../components/StepNine';
import { REACT_APP_API_ENDPOINT, REACT_APP_API_NAME } from '../config/aws-config';
import CryptoJS from 'crypto-js';
import StepTen from '../components/StepTen';
import Loader from '../components/Loader';
import save from "../assets/images/save.svg"
import LoaderScreen from '../components/LoaderScreen';
import PdfHtml from '../components/PdfHtml';
import LoginWithGoogle from '../components/Loginwithgoogle';
import LoginWithFacebook from '../components/Loginwithfacebook';
import { ToastContainer, toast } from 'react-toastify';


const MyApp = () => {

    let email_pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const ref = useRef();
    const [isMainApp, setIsMainApp] = useState(false);
    const [progressValue, setProgressValue] = useState(0);
    const [activeStep, setActiveStep] = useState()
    const [applicationData, setApplicationData] = useState([]);
    const [propertyName, setPropertyName] = useState('');
    const [isMainLoader, setIsMainLoader] = useState(false)
    const [responseData, setResponseData] = useState({})
    const [userData, setUserData] = useState({})
    const [saveLaterModal, setSaveLaterModal] = useState(false)
    const [saved, setSaved] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const stepToKey = {
        1: 'about',
        2: 'applicationInfo',
        3: 'residentHistory',
        4: 'employment',
        5: 'otherIncome',
        6: 'generalInfo',
        7: 'background',
        8: 'otherInfo',
        9: 'documents'
    };
    const [isAppListOpen, setIsAppListOpen] = useState(false)
    const [loginModal, setLoginModal] = useState(false);
    const [loaderModal, setLoaderModal] = useState(false)
    const [isForgotPassword, setIsForgotPassword] = useState(false)
    const [forgotPasswordEmail, setForgotPasswordEmail] = useState('')
    const [forgotPasswordEmailError, setForgotPasswordEmailError] = useState('')
    const [isResetPassword, setIsResetPassword] = useState(false)
    const [openApplicationList, setOpenApplicationList] = useState(false)
    const [modalData, setModalData] = useState({
        emailModal: "",
        passwordModal: "",
        otp: ""
    });
    const [modalErrors, setModalErrors] = useState({
        emailModal: "",
        passwordModal: "",
        otp: ""
    })
    const [resetPassword, setResetPassword] = useState({
        confirmationCode: '',
        newPassword: '',
        confirmNewPassword: ''
    })
    const [resetPasswordError, setResetPasswordError] = useState({
        confirmationCodeError: '',
        newPasswordError: '',
        confirmNewPasswordError: ''
    })
    const [isOtpSent, setIsOtpSent] = useState(false)
    const [propertyListData, setPropertyListData] = useState([])

    useEffect(() => {
        initialize()
        getPropertyList()
    }, [])

    const getPropertyList = async () => {
        setIsLoading(true)
        try {
            await API.get(REACT_APP_API_NAME, "/property/list", {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            })
                .then((response) => {
                    const sortedAddresses = response.sort((a, b) => {
                        return a.localeCompare(b);
                    });
                    setPropertyListData(sortedAddresses)
                    setIsLoading(false)
                })
                .catch((err) => {
                    console.log(err)
                    setIsLoading(false)
                })
        } catch(err) {
            console.log(err)
            setIsLoading(false)
        }
    }

    const initialize = async () => {
        setIsLoading(true)
        let data = JSON.parse(localStorage.getItem('userData'));
        setUserData(data)
        let cognitoUserData = await getCredinitals();
        if(data?.id) {
            await fetchData(data)
        } else if(cognitoUserData?.id) {
            setActiveStep(1)
            setIsMainApp(false)
        } else {
            setActiveStep(0)
            setIsMainApp(true)
        }
        setIsLoading(false)
    }

    const getCredinitals = async () => {
        let params = (new URL(document.location)).searchParams;
        let code = params.get("code");
        let userData = {}
        if(code) {
            try {
                let user = await Auth.currentAuthenticatedUser();
                localStorage.setItem('accessToken', user.signInUserSession.idToken.jwtToken)
                await API.post(REACT_APP_API_NAME, "/user", {
                    body: {
                        cognitoId: user.username,
                        email: user.attributes.email
                    },
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                })
                    .then((response) => {
                        localStorage.setItem("userData", JSON.stringify(response.data));
                        userData = response.data
                    })
                    .catch((err) => {
                        console.log(err)
                        toast.error(err?.response?.data?.message)
                        localStorage.removeItem("accessToken");
                        localStorage.removeItem("userData");
                        localStorage.removeItem("propertyName")
                        setResponseData({})
                        setPropertyName('')
                        setIsMainApp(true)
                        setActiveStep(0)
                    })
            } catch(err) {
                console.log(err)
            }
        }
        return userData
    }

    const fetchData = async (userData) => {
        await API.post(REACT_APP_API_NAME, "/application/userid", {
            body: {
                userId: userData?.id,
                propertyName: localStorage.getItem("propertyName") || ""
            },
            headers: {
                Authorization: `Bearer ${localStorage.getItem('accessToken')}`
            }
        })
            .then((res) => {
                if(res.length && !res[0]?.isCompleted) {
                    res = res[0]
                    setResponseData(res)
                    setPropertyName(res.propertyName)
                    let data = []
                    let initialStep = 0;
                    Object.keys(res).map((key) => {
                        let step = null
                        switch(key) {
                            case 'about':
                                step = 1;
                                break;
                            case 'applicationInfo':
                                step = 2;
                                break;
                            case 'residentHistory':
                                step = 3;
                                break;
                            case 'employment':
                                step = 4;
                                break;
                            case 'otherIncome':
                                step = 5;
                                break;
                            case 'generalInfo':
                                step = 6;
                                break;
                            case 'background':
                                step = 7;
                                break;
                            case 'otherInfo':
                                step = 8;
                                break;
                            case 'documents':
                                step = 9;
                                break;
                            default:
                                break;
                        }
                        if(step) {
                            if(step > initialStep) initialStep = step
                            data.push({
                                step,
                                stepData: res[key]
                            })
                        }
                    })
                    setApplicationData(data)
                    if(initialStep === 0) {
                        setIsMainApp(true)
                    } else {
                        setActiveStep(initialStep)
                    }
                } else {
                    setActiveStep(0)
                    setIsMainApp(true)
                }
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message)
                localStorage.removeItem("accessToken");
                localStorage.removeItem("userData");
                localStorage.removeItem("propertyName")
                setPropertyName('')
                setIsMainApp(true)
                setActiveStep(0)
                console.log(err)
            })
    }

    useEffect(() => {
        if(activeStep >= 1) {
            setIsMainApp(false)
        } else if(activeStep === 0) {
            setIsMainApp(true)
        }
        setProgressValue(activeStep * 10)
    }, [activeStep])

    const handleData = async (step, stepData) => {
        if(!propertyName) {
            setIsMainApp(true)
            setActiveStep(0)
            return
        }
        setIsMainLoader(true)
        let updatedData = [...applicationData]
        let isActiveStepRun = false
        let index = updatedData.findIndex((item) => item.step === step)
        if(index === -1) {
            updatedData.push({
                step,
                stepData
            })
        } else {
            updatedData[index] = {
                step,
                stepData
            }
        }
        if(step === 1 && !responseData.id) {
            let user = JSON.parse(localStorage.getItem("userData"))
            let body = {
                userId: user?.id,
                propertyName: propertyName,
                isCompleted: false,
                activeStep: step,
                [stepToKey[activeStep]]: updatedData.at(-1).stepData,
                approvalStatus: 'new',
            }
            const secretKey = process.env.REACT_APP_ENCRYPTION_SECRET_KEY;

            const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(body), secretKey).toString();
            await API.post(REACT_APP_API_NAME, "/application", {
                body: {
                    data: encryptedData
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            })
                .then(async (response) => {
                    setResponseData(response.data)
                    await API.put(REACT_APP_API_NAME, "/user", {
                        body: {
                            ...user,
                            firstName: body.about.firstName,
                            lastName: body.about.lastName
                        },
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                        }
                    })
                        .then((response) => {
                            localStorage.setItem("userData", JSON.stringify(response.data));
                            setUserData(response.data)
                        })
                        .catch((err) => {
                            console.log(err)

                        })
                    setIsMainLoader(false)
                })
                .catch((err) => {
                    console.log(err)
                    toast.error(err?.response?.data?.message)
                    setResponseData({})
                    setPropertyName('')
                    setIsMainApp(true)
                    setActiveStep(0)
                    setIsMainLoader(false)
                    updatedData = []
                    if(err?.response?.status === 409) {
                        localStorage.removeItem("propertyName")
                        isActiveStepRun = true
                    } else {
                        localStorage.removeItem("accessToken");
                        localStorage.removeItem("userData");
                        localStorage.removeItem("propertyName")
                    }
                })
        } else {
            let user = JSON.parse(localStorage.getItem("userData"))
            let body = {
                id: responseData.id,
                userId: user?.id,
                createdAt: responseData?.createdAt,
                propertyName: propertyName,
                isCompleted: step === 9 ? true : false,
                activeStep: step,
                approvalStatus: 'new',
                recordId: responseData.recordId,
            }
            updatedData.map((item) => {
                body[stepToKey[item.step]] = item.stepData
            })

            if(step === 9) {
                body = { ...body, submittedDate: new Date() }
                // let pdfLink = await generatePDF(body)
                // body = { ...body, pdfLink: pdfLink }
            }

            const secretKey = process.env.REACT_APP_ENCRYPTION_SECRET_KEY;

            const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(body), secretKey).toString();


            await API.put(REACT_APP_API_NAME, "/application", {
                body: {
                    data: encryptedData
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            })
                .then((response) => {
                    if(step === 9) {
                        setResponseData({})
                        setPropertyName('')
                        localStorage.removeItem("propertyName")
                    } else {
                        setResponseData(response.data)
                    }
                    setIsMainLoader(false)
                })
                .catch((err) => {
                    console.log(err);
                    toast.error(err?.response?.data?.message)
                    localStorage.removeItem("accessToken");
                    localStorage.removeItem("userData");
                    localStorage.removeItem("propertyName")
                    setResponseData({})
                    setPropertyName('')
                    setIsMainApp(true)
                    setActiveStep(0)
                    setIsMainLoader(false)
                })
        }
        setApplicationData(updatedData)
        if(isActiveStepRun) {
            return true
        }
    }

    const saveForm = async () => {
        if(!propertyName) {
            setIsMainApp(true)
            setActiveStep(0)
            setSaveLaterModal(false)
            return
        }

        const { step, stepData } = ref.current

        if(ref.current) {
            setIsMainLoader(true)
            let updatedData = [...applicationData]
            let index = updatedData.findIndex((item) => item.step === step)
            if(index === -1) {
                updatedData.push({
                    step,
                    stepData
                })
            } else {
                updatedData[index] = {
                    step,
                    stepData
                }
            }
            let user = JSON.parse(localStorage.getItem("userData"))

            let body = {
                id: responseData.id,
                userId: user?.id,
                createdAt: responseData?.createdAt,
                propertyName: propertyName,
                isCompleted: false,
                activeStep: step,
                approvalStatus: 'new',
                recordId: responseData.recordId,
            }
            updatedData.map((item) => {
                body[stepToKey[item.step]] = item.stepData
            })

            const secretKey = process.env.REACT_APP_ENCRYPTION_SECRET_KEY;

            const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(body), secretKey).toString();

            await API.post(REACT_APP_API_NAME, "/application/send-mail", {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                },
                body: {
                    email: user?.email,
                    firstName: user?.firstName,
                    url: "https://www.app.denaliarizona.com"
                }
            })
                .then(async (response) => {
                })
                .catch((err) => {
                    console.log(err)
                    toast.error(err?.response?.data?.message)
                    localStorage.removeItem("accessToken");
                    localStorage.removeItem("userData");
                    localStorage.removeItem("propertyName")
                    setResponseData({})
                    setPropertyName('')
                    setIsMainApp(true)
                    setActiveStep(0)
                })

            await API.put(REACT_APP_API_NAME, "/application", {
                body: {
                    data: encryptedData
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            })
                .then((response) => {
                    setResponseData(response.data)
                    setIsMainLoader(false)
                    setSaved(true)
                    setActiveStep(0)
                    setIsMainApp(true)
                    setOpenApplicationList(true);
                    localStorage.removeItem("propertyName")
                })
                .catch((err) => {
                    console.log(err)
                    toast.error(err?.response?.data?.message)
                    localStorage.removeItem("accessToken");
                    localStorage.removeItem("userData");
                    localStorage.removeItem("propertyName")
                    setResponseData({})
                    setPropertyName('')
                    setIsMainApp(true)
                    setActiveStep(0)
                    setIsMainLoader(false)
                })

            setApplicationData(updatedData)
        }
    }

    const handleNextStep = (step) => {
        if(!localStorage.getItem('accessToken') && step >= 2) {
            setActiveStep(0)
        } else {
            setActiveStep(step)
        }
    }

    const handleModalChange = (event) => {
        const { name, value } = event.target
        let error = { ...modalErrors }

        if(name === "emailModal") {
            if(!value) {
                error = { ...error, emailModal: "email is required" }
            } else if(!email_pattern.test(value)) {
                error = { ...error, emailModal: "email is invaild" }
            } else {
                error = { ...error, emailModal: "" }
            }
        }
        if(name === "password") {
            if(!value) {
                error = { ...error, passwordModal: "password is required" }
            } else if(value.length < 8) {
                error = { ...error, passwordModal: "minimum 8 charcters required" }
            } else {
                error = { ...error, passwordModal: "" }
            }
        }
        if(isOtpSent) {
            if(name === "otp") {
                if(!value) {
                    error = { ...error, otp: "Verification code is required" }
                } else {
                    error = { ...error, otp: "" }
                }
            }
        }

        setModalErrors(error)
        if(!isOtpSent) {
            setModalData({ ...modalData, [name]: value })
        } else {
            if(name === "otp") {
                setModalData({ ...modalData, [name]: value })
            }
        }
    }

    const handleForgotModal = (event) => {
        const { name, value } = event.target
        let error = forgotPasswordEmailError
        if(name === "forgotPasswordEmail") {
            if(!value) {
                error = "email is required"
            } else if(!email_pattern.test(value)) {
                error = "email is invaild"
            } else {
                error = ""
            }
        }
        setForgotPasswordEmailError(error)
        setForgotPasswordEmail(value)
    }

    const handleResetPassword = (event) => {
        const { name, value } = event.target
        let error = { ...resetPasswordError }

        if(name === "confirmationCode") {
            if(!value) {
                error = { ...error, confirmationCodeError: "Confirmation code is required" }
            } else {
                error = { ...error, confirmationCodeError: "" }
            }
        }
        if(name === "newPassword") {
            if(!value) {
                error = { ...error, newPasswordError: "Password is required" }
            } else if(value.length < 8) {
                error = { ...error, newPasswordError: "minimum 8 charcters required" }
            } else {
                error = { ...error, newPasswordError: "" }
            }
        }
        if(name === "confirmNewPassword") {
            if(!value) {
                error = { ...error, confirmNewPasswordError: "Confirm password is required" }
            } else if(resetPassword.newPassword !== value) {
                error = { ...error, confirmNewPasswordError: "Passwords do not match" }
            } else {
                error = { ...error, confirmNewPasswordError: "" }
            }
        }
        setResetPasswordError(error)
        setResetPassword({ ...resetPassword, [name]: value })
    }

    const submitModal = async (e) => {
        e.preventDefault()
        if(!isOtpSent) {

            let error = {};
            let isError = false;
            if(!modalData.emailModal) {
                error = { ...error, emailModal: "email is required" }
                isError = true
            } else if(!email_pattern.test(modalData.emailModal)) {
                error = { ...error, emailModal: "email is invaild" }
                isError = true
            }
            if(!modalData.passwordModal) {
                error = { ...error, passwordModal: "password is required" }
                isError = true
            } else if(modalData.passwordModal.length < 8) {
                error = { ...error, passwordModal: "minimum 8 charcters required" }
                isError = true
            }
            if(isError) {
                setModalErrors(error)
                return
            }
            setLoaderModal(true)

            try {
                const signInRes = await Auth.signIn({
                    username: modalData.emailModal,
                    password: modalData.passwordModal,
                });

                if(signInRes) {
                    localStorage.setItem('accessToken', signInRes.signInUserSession.idToken.jwtToken)
                    await API.get(REACT_APP_API_NAME, `/user/cognitoid/${signInRes.username}`, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                        }
                    })
                        .then(async (response) => {
                            if(response) {
                                localStorage.setItem("userData", JSON.stringify(response));
                                // await fetchData(response)
                                setActiveStep(0)
                                setIsMainApp(true)
                                setLoaderModal(false)
                                setOpenApplicationList(true);
                            } else {
                                await API.post(REACT_APP_API_NAME, "/user", {
                                    body: {
                                        cognitoId: signInRes.username,
                                        email: modalData.emailModal
                                    },
                                    headers: {
                                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                                    }
                                })
                                    .then((response) => {
                                        localStorage.setItem("userData", JSON.stringify(response.data));
                                        setUserData(response.data)
                                        setLoaderModal(false)
                                        setActiveStep(0)
                                        setIsMainApp(true)
                                    })
                                    .catch((err) => {
                                        console.log(err)
                                    })
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                            toast.error(err?.response?.data?.message)
                            localStorage.removeItem("accessToken");
                            localStorage.removeItem("userData");
                            localStorage.removeItem("propertyName")
                            setResponseData({})
                            setPropertyName('')
                            setIsMainApp(true)
                            setActiveStep(0)
                            setLoaderModal(false)
                        })
                }
                setLoginModal(false)
            } catch(error) {
                if(error.code === "UserNotConfirmedException") {
                    try {
                        const reSignInRes = await Auth.resendSignUp(modalData.emailModal);
                        toast.success("Verification code sent to your mail.")
                        setIsOtpSent(true)
                    } catch(er) {
                        toast.error(er.message)
                    }
                } else {
                    toast.error(error.message)
                }
                setLoaderModal(false)
            }

        } else {
            let error = {};
            let isError = false;

            if(!modalData.otp) {
                error = { ...error, otp: "Verification code is required" }
                isError = true
            }

            if(isError) {
                setModalErrors(error)
                return
            }

            setLoaderModal(true)

            try {
                const res = await Auth.confirmSignUp(modalData.emailModal, modalData.otp);
                setIsOtpSent(false)
                try {
                    const signInRes = await Auth.signIn({
                        username: modalData.emailModal,
                        password: modalData.passwordModal,
                    });

                    if(signInRes) {
                        localStorage.setItem('accessToken', signInRes.signInUserSession.idToken.jwtToken)
                    }

                    await API.post(REACT_APP_API_NAME, "/user", {
                        body: {
                            cognitoId: signInRes.username,
                            email: modalData.emailModal
                        },
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                        }
                    })
                        .then((response) => {
                            localStorage.setItem("userData", JSON.stringify(response.data));
                            setUserData(response.data)
                            setLoaderModal(false)
                            setLoginModal(false)
                            setActiveStep(0)
                            setIsMainApp(true)
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                } catch(err) {
                    toast.error(err.message)
                    setLoaderModal(false)
                }
            } catch(error) {
                toast.error(error.message)
                setLoaderModal(false)
            }

        }
    }

    const submitResetPassword = async (e) => {
        e.preventDefault()
        let error = {};
        let isError = false;
        if(!resetPassword.confirmationCode) {
            error = { ...error, confirmationCodeError: "Confirmation code is required" }
            isError = true
        }
        if(!resetPassword.newPassword) {
            error = { ...error, newPasswordError: "password is required" }
            isError = true
        } else if(resetPassword.newPassword.length < 8) {
            error = { ...error, newPasswordError: "minimum 8 charcters required" }
            isError = true
        }
        if(!resetPassword.confirmNewPassword) {
            error = { ...error, confirmNewPasswordError: "Confirm password is required" }
            isError = true
        } else if(resetPassword.newPassword !== resetPassword.confirmNewPassword) {
            error = { ...error, confirmNewPasswordError: "Passwords do not match" }
            isError = true
        }
        if(isError) {
            setResetPasswordError(error)
            return
        }

        setLoaderModal(true)
        await Auth.forgotPasswordSubmit(forgotPasswordEmail, resetPassword.confirmationCode, resetPassword.newPassword)
            .then(async (response) => {
                setLoaderModal(false)
                setIsForgotPassword(false)
            })
            .catch((err) => {
                console.log('err', err)
                setLoaderModal(false)
            })

    }

    const submitForgotPasswordEmail = async (e) => {
        e.preventDefault()
        let error = {};
        let isError = false;
        if(!forgotPasswordEmail) {
            error = "email is required"
            isError = true
        } else if(!email_pattern.test(forgotPasswordEmail)) {
            error = "email is invaild"
            isError = true
        }
        if(isError) {
            setForgotPasswordEmailError(error)
            return
        }
        setLoaderModal(true)

        await Auth.forgotPassword(forgotPasswordEmail)
            .then(async (response) => {
                toast.success("Verification code snet to your mail.")
                setLoaderModal(false)
                setIsResetPassword(true)
            })
            .catch((err) => {
                toast.error(err.message)
                setLoaderModal(false)
            })
    }

    const handleCloseModal = () => {
        setLoginModal(false)
        setIsForgotPassword(false)
        setIsResetPassword(false)
    }

    return (
        <>
            <main className='main-app'>
                <Modal
                    show={loginModal}
                    onHide={handleCloseModal}
                    backdrop="static"
                    keyboard={true}
                >
                    <Modal.Header closeButton className='border-0'></Modal.Header>
                    {!isForgotPassword ?
                        <Modal.Body>
                            <h3 className='step-title mb-0'>Log in to finish your application</h3>
                            {/* <div className='sign-up-page border-bottom login-popup'>
                                <LoginWithGoogle text="Log in with Google" />
                                <div className='login-fb-btn'>
                                    <LoginWithFacebook text="Log in with Facebook" />
                                </div>
                            </div> */}
                            <Form className='mt-4' onSubmit={submitModal}>
                                <Form.Group className='mb-3' controlId="emailModal">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        name="emailModal"
                                        value={modalData.email}
                                        onChange={handleModalChange}
                                        isInvalid={modalErrors.email}
                                        disabled={isOtpSent}
                                    />
                                    <Form.Control.Feedback type="invalid">{modalErrors.email}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className='mb-3' controlId="passwordModal">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        name="passwordModal"
                                        value={modalData.password}
                                        onChange={handleModalChange}
                                        isInvalid={modalErrors.password}
                                        disabled={isOtpSent}
                                    />
                                    <Form.Control.Feedback type="invalid">{modalErrors.password}</Form.Control.Feedback>
                                </Form.Group>
                                {isOtpSent && <Form.Group className='mb-3 col-md-7' controlId="otp">
                                    <Form.Label>Verification code</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="otp"
                                        value={modalData.otp}
                                        onChange={handleModalChange}
                                        isInvalid={modalErrors.otp}
                                    />
                                    <Form.Control.Feedback type="invalid">{modalErrors.otp}</Form.Control.Feedback>
                                </Form.Group>}
                                <p className="text-decoration-underline link-text pointer mb-4" onClick={() => setIsForgotPassword(true)}>Forgot Password?</p>
                                <Button type="submit" className='text-uppercase step-button d-block mx-auto mb-3'>{loaderModal ? <Loader /> : 'Log In'}</Button>
                            </Form>
                        </Modal.Body>
                        :
                        !isResetPassword ?
                            <Modal.Body>
                                <h3 className='step-title mb-0'>Forgot Password </h3>
                                <Form className='mt-4' onSubmit={submitForgotPasswordEmail}>
                                    <Form.Group className='mb-3' controlId="forgotPasswordEmail">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            type="email"
                                            name="forgotPasswordEmail"
                                            value={forgotPasswordEmail}
                                            onChange={handleForgotModal}
                                            isInvalid={forgotPasswordEmailError}
                                        />
                                        <Form.Control.Feedback type="invalid">{forgotPasswordEmailError}</Form.Control.Feedback>
                                    </Form.Group>

                                    <p className="text-decoration-underline link-text pointer mb-4" onClick={() => setIsForgotPassword(false)}>Back to Login </p>
                                    <Button type="submit" className='text-uppercase step-button d-block mx-auto mb-3'>{loaderModal ? <Loader /> : 'Send Code'}</Button>
                                </Form>
                            </Modal.Body>
                            :
                            <Modal.Body>
                                <h3 className='step-title mb-0'>Reset Password</h3>
                                <Form className='mt-4' onSubmit={submitResetPassword}>
                                    <Form.Group className='mb-3' controlId="confirmationCode">
                                        <Form.Label>Confirmation Code</Form.Label>
                                        <Form.Control
                                            type="number"
                                            name="confirmationCode"
                                            value={resetPassword.confirmationCode}
                                            onChange={handleResetPassword}
                                            isInvalid={resetPasswordError.confirmationCodeError}
                                        />
                                        <Form.Control.Feedback type="invalid">{resetPasswordError.confirmationCodeError}</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className='mb-3' controlId="newPassword">
                                        <Form.Label>New Password</Form.Label>
                                        <Form.Control
                                            type="password"
                                            name="newPassword"
                                            value={resetPassword.newPassword}
                                            onChange={handleResetPassword}
                                            isInvalid={resetPasswordError.newPasswordError}
                                        />
                                        <Form.Control.Feedback type="invalid">{resetPasswordError.newPasswordError}</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className='mb-3' controlId="confirmNewPassword">
                                        <Form.Label>Confirm New Password</Form.Label>
                                        <Form.Control
                                            type="password"
                                            name="confirmNewPassword"
                                            value={resetPassword.confirmNewPassword}
                                            onChange={handleResetPassword}
                                            isInvalid={resetPasswordError.confirmNewPasswordError}
                                        />
                                        <Form.Control.Feedback type="invalid">{resetPasswordError.confirmNewPasswordError}</Form.Control.Feedback>
                                    </Form.Group>

                                    <p className="text-decoration-underline link-text pointer mb-4" onClick={() => setIsForgotPassword(false)}>Back to Login </p>
                                    <Button type="submit" className='text-uppercase step-button d-block mx-auto mb-3'>{loaderModal ? <Loader /> : 'Submit'}</Button>
                                </Form>
                            </Modal.Body>
                    }
                </Modal>
                <Modal
                    show={saveLaterModal}
                    onHide={() => setSaveLaterModal(false)}
                    backdrop="static"
                    keyboard={true}
                >
                    <Modal.Body>
                        <div className='finish-later'>
                            {
                                saved ?
                                    <>
                                        <div style={{ height: "84px", width: "84px" }}>
                                            <img src={save} />
                                        </div>
                                        <div className='finish-later-title'>Application Saved</div>
                                        <p className='finish-later-desc'>Check your inbox for an email we sent you. To finish your rental application later, click the link in the email to pick up where you left off.</p>
                                        <div className='finish-later-btns d-flex position-relative w-100 justify-content-center'>
                                            <Button type="button" onClick={() => { setSaveLaterModal(false); setSaved(false) }} className='finish-later-btn finish-later-btn-lite w-50'>Done</Button>
                                        </div>
                                    </> : <>
                                        <svg viewBox="0 0 24 24" color="#042238" style={{ height: "84px", width: "84px" }}>
                                            <g stroke="#042238" strokeWidth="1.25" fillOpacity="0" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10">
                                                <path d="M23.5 18c0 .829-.671 1.5-1.5 1.5h-20c-.828 0-1.5-.671-1.5-1.5v-15c0-.828.672-1.5 1.5-1.5h20c.829 0 1.5.672 1.5 1.5v15zM5 22.5h14" />
                                                <path d="M15.5 22.5h-7l1-3h5zM2.5 3.5h19v12h-19z" />
                                                <circle cx="12" cy="17.5" r=".5" />
                                            </g>
                                        </svg>
                                        <div className='finish-later-title'>Don’t miss out on this rental!</div>
                                        <p className='finish-later-desc'>Finish your application as soon as possible so the landlord isn’t waiting on you. If you can’t finish it right now, select the “Save & Finish Later” button below, and we’ll email you a link so you can pick up where you left off.</p>
                                        <div className='finish-later-btns d-flex position-relative w-100'>
                                            <Button type="button" onClick={() => setSaveLaterModal(false)} className='finish-later-btn finish-later-btn-lite'>BACK TO APPLICATION</Button>
                                            <Button type="button" onClick={saveForm} className='finish-later-btn position-relative'>{isMainLoader ? <Loader /> : 'SAVE & FINISH LATER'}</Button>
                                        </div>
                                    </>
                            }
                        </div>
                    </Modal.Body>
                </Modal>
                <div className='form-container container'>
                    <div className='main-logo'>
                        {(activeStep > 0 || isAppListOpen) && <span className='d-flex justify-content-center'><SiteLogo /></span>}
                    </div>
                    {
                        (activeStep > 0 && activeStep < 10) && <div className='position-relative py-3'>
                            <div className='d-flex justify-content-between'>
                                <button type='button' onClick={() => setActiveStep(activeStep - 1)} className='back-btn d-flex align-items-center'>
                                    <svg width="18" height="18" color="#033A6D" viewBox="0 0 18 18" fill="#033A6D"><path d="M17.25 8.25H2.678l7.579-6.948A.75.75 0 009.243.197l-9 8.25-.01.012c-.023.02-.041.044-.06.067-.013.016-.027.03-.038.046-.02.029-.035.06-.051.09-.008.013-.016.026-.022.04a.715.715 0 00-.039.12c-.001.007-.006.016-.007.024a.798.798 0 000 .307c.001.01.005.017.007.024.01.042.022.083.04.12.005.015.013.027.021.04.016.032.031.062.051.09.01.017.025.03.037.046.02.023.038.046.06.067l.011.012 9 8.25a.748.748 0 001.06-.045.75.75 0 00-.046-1.06L2.678 9.75H17.25a.75.75 0 100-1.5"></path></svg>
                                    <span className='d-block ms-1'>BACK</span>
                                </button>
                                {activeStep > 1 && <Button type="button" className='text-uppercase save-finish-btn border-btn d-block' onClick={() => setSaveLaterModal(true)}>SAVE & FINISH LATER</Button>}
                            </div>
                            <ProgressBar variant="success" now={progressValue} />
                        </div>
                    }

                    {
                        isLoading ? <LoaderScreen /> :
                            <>
                                {
                                    isMainApp && <MainStep setOpenApplicationList={setOpenApplicationList} openApplicationList={openApplicationList} setLoginModal={setLoginModal} propertyName={propertyName} setActiveStep={handleNextStep} setPropertyName={setPropertyName} loginModal={loginModal} fetchData={fetchData} setApplicationData={setApplicationData} propertyListData={propertyListData} setIsAppListOpen={setIsAppListOpen} />
                                }

                                {
                                    activeStep === 1 && <StepOne setLoginModal={setLoginModal} key={applicationData.length} fetchData={fetchData} setUserData={setUserData} isMainLoader={isMainLoader} setIsMainLoader={setIsMainLoader} activeData={applicationData.filter((item) => item.step === 1)[0]?.stepData} setActiveStep={handleNextStep} setMainData={handleData} />
                                }

                                {
                                    activeStep === 2 && <StepTwo ref={ref} key={applicationData.length} isMainLoader={isMainLoader} activeData={applicationData.filter((item) => item.step === 2)[0]?.stepData} setActiveStep={handleNextStep} setMainData={handleData} applicationData={applicationData} />
                                }

                                {
                                    activeStep === 3 && <StepThree ref={ref} key={applicationData.length} isMainLoader={isMainLoader} activeData={applicationData.filter((item) => item.step === 3)[0]?.stepData} setActiveStep={handleNextStep} setMainData={handleData} />
                                }

                                {
                                    activeStep === 4 && <StepFour ref={ref} key={applicationData.length} isMainLoader={isMainLoader} activeData={applicationData.filter((item) => item.step === 4)[0]?.stepData} setActiveStep={handleNextStep} setMainData={handleData} />
                                }

                                {
                                    activeStep === 5 && <StepFive ref={ref} key={applicationData.length} isMainLoader={isMainLoader} activeData={applicationData.filter((item) => item.step === 5)[0]?.stepData} setActiveStep={handleNextStep} setMainData={handleData} />
                                }

                                {
                                    activeStep === 6 && <StepSix ref={ref} key={applicationData.length} isMainLoader={isMainLoader} activeData={applicationData.filter((item) => item.step === 6)[0]?.stepData} setActiveStep={handleNextStep} setMainData={handleData} setIsMainLoader={setIsMainLoader} />
                                }

                                {
                                    activeStep === 7 && <StepSeven ref={ref} key={applicationData.length} isMainLoader={isMainLoader} activeData={applicationData.filter((item) => item.step === 7)[0]?.stepData} setActiveStep={handleNextStep} setMainData={handleData} />
                                }

                                {
                                    activeStep === 8 && <StepEight ref={ref} key={applicationData.length} isMainLoader={isMainLoader} activeData={applicationData.filter((item) => item.step === 8)[0]?.stepData} setActiveStep={handleNextStep} setMainData={handleData} />
                                }

                                {
                                    activeStep === 9 && <StepNine ref={ref} key={applicationData.length} isMainLoader={isMainLoader} activeStep={activeStep} activeData={applicationData.filter((item) => item.step === 9)[0]?.stepData} setActiveStep={handleNextStep} setMainData={handleData} />
                                }
                                {
                                    activeStep === 10 && <StepTen setActiveStep={setActiveStep} setIsMainApp={setIsMainApp} setApplicationData={setApplicationData} setIsAppListOpen={setIsAppListOpen} />
                                }
                            </>
                    }
                    <div className='pt-5'>

                    </div>
                </div>
            </main >
        </>
    )
}

export default MyApp