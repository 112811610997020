import React, { useState, useEffect } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import DatePicker from 'react-date-picker';
import LoginWithGoogle from './Loginwithgoogle';
import LoginWithFacebook from './Loginwithfacebook';
import { Auth, API } from 'aws-amplify';
import Loader from './Loader';
import { REACT_APP_API_NAME } from "../config/aws-config";
import { toast } from 'react-toastify';

const StepOne = ({ activeData, setLoginModal, setUserData, setActiveStep, setMainData, isMainLoader, setIsMainLoader }) => {

    let email_pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    let phone_pattern = /^(\(\d{3}\)\s?\d{3}-\d{4}|\d{3}-\d{3}-\d{4}|\d{10})$/;

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isSecondSubmitted, setIsSecondSubmitted] = useState(false);
    const [isUser, setIsUser] = useState(false)
    const [data, setData] = useState({
        email: "",
        password: "",
        otp: ""
    });
    const [errors, setErrors] = useState({
        email: "",
        password: "",
        otp: ""
    })
    const [secondData, setSecondData] = useState({
        applying_as: "Tenant",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        dateOfBirth: "",
        realtor_worker: "No",
        drivers_license: "",
        ssn: "",
        realtor_name: "",
        realtor_phoneNumber: "",
        realtor_email: "",
    })
    const [secondErrors, setSecondErrors] = useState({
        applying_as: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        dateOfBirth: "",
        realtor_worker: "",
        drivers_license: "",
        ssn: "",
        realtor_name: "",
        realtor_phoneNumber: "",
        realtor_email: "",
    })
    const [userSub, setUserSub] = useState('')
    const [isEmailVerify, setIsEmailVerify] = useState(false)
    const [isOtpSent, setIsOtpSent] = useState(false)
    const [isAnyError, setIsAnyError] = useState(false)

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("userData"));
        if(user?.id) {
            setIsUser(true)
            setData({
                email: user.email,
                password: "",
                otp: ""
            })
            setSecondData({
                applying_as: "Tenant",
                firstName: "",
                lastName: "",
                phoneNumber: "",
                dateOfBirth: "",
                realtor_worker: "No",
                drivers_license: "",
                ssn: "",
                realtor_name: "",
                realtor_phoneNumber: "",
                realtor_email: "",
            })
            if(activeData) {
                setData(activeData)
                setSecondData(activeData)
            }
        } else {
            setIsUser(false)
        }

    }, [isEmailVerify])

    const handleChange = (event) => {
        const { name, value } = event.target
        let error = { ...errors }
        if(isSubmitted) {
            if(name === "email") {
                if(!value) {
                    error = { ...error, email: "email is required" }
                } else if(!email_pattern.test(value)) {
                    error = { ...error, email: "email is invaild" }
                } else {
                    error = { ...error, email: "" }
                }
            }
            if(name === "password") {
                if(!value) {
                    error = { ...error, password: "password is required" }
                } else if(value.length < 8) {
                    error = { ...error, password: "minimum 8 charcters required" }
                } else {
                    error = { ...error, password: "" }
                }
            }
            if(isOtpSent) {
                if(name === "otp") {
                    if(!value) {
                        error = { ...error, otp: "Verification code is required" }
                    } else {
                        error = { ...error, otp: "" }
                    }
                }
            }
        }
        setErrors(error)
        if(!isOtpSent) {
            setData({ ...data, [name]: value })
        } else {
            if(name === "otp") {
                setData({ ...data, [name]: value })
            }
        }
    }

    const secondHandleChange = (event, isRealtorWorker) => {
        const { name, value } = event.target
        let error = { ...secondErrors }
        if(isSecondSubmitted) {
            if(name === "firstName") {
                if(!value) {
                    error = { ...error, firstName: "First name is required" }
                } else {
                    error = { ...error, firstName: "" }
                }
            }
            if(name === "lastName") {
                if(!value) {
                    error = { ...error, lastName: "Last name is required" }
                } else {
                    error = { ...error, lastName: "" }
                }
            }
            if(name === "phoneNumber") {
                if(!value) {
                    error = { ...error, phoneNumber: "Phone number is required" }
                } else if(!phone_pattern.test(value)) {
                    error = { ...error, phoneNumber: "Phone number is invaild" }
                } else {
                    error = { ...error, phoneNumber: "" }
                }
            }
            if(name === "drivers_license") {
                if(!value) {
                    error = { ...error, drivers_license: "Drivers license is required" }
                } else {
                    error = { ...error, drivers_license: "" }
                }
            }
            if(name === "ssn") {
                if(!value) {
                    error = { ...error, ssn: "SSN is required" }
                } else {
                    error = { ...error, ssn: "" }
                }
            }
            if(name === "dateOfBirth") {
                if(!value) {
                    error = { ...error, dateOfBirth: "Date of birth is required" }
                } else if(!validateAge(value)) {
                    error = { ...error, dateOfBirth: "Must be 18 year old" }
                } else {
                    error = { ...error, dateOfBirth: "" }
                }
            }
            if(secondData.realtor_worker === "Yes") {
                if(name === "realtor_name") {
                    if(!value) {
                        error = { ...error, realtor_name: "Realtor name is required" }
                    } else {
                        error = { ...error, realtor_name: "" }
                    }
                }
                if(name === "realtor_phoneNumber") {
                    if(!value) {
                        error = { ...error, realtor_phoneNumber: "Realtor phone number is required" }
                    } else if(!phone_pattern.test(value)) {
                        error = { ...error, realtor_phoneNumber: "Realtor phone number is invaild" }
                    } else {
                        error = { ...error, realtor_phoneNumber: "" }
                    }
                }
                if(name === "realtor_email") {
                    if(!value) {
                        error = { ...error, realtor_email: "Realtor email is required" }
                    } else if(!email_pattern.test(value)) {
                        error = { ...error, realtor_email: "Realtor email is invaild" }
                        if(value === "N/A" || value === "n/a" || value === "N/a" || value === "n/A"){
                            error = { ...error, realtor_email: "" }
                        }
                    } else {
                        error = { ...error, realtor_email: "" }
                    }
                }
            }
            if(isRealtorWorker === "No") {
                error = { ...error, realtor_name: "", realtor_phoneNumber: "", realtor_email: "" }
            }
        }
        setSecondErrors(error)
        if(name === "applying_as" || name === "realtor_worker") {
            setSecondData({ ...secondData, [name]: event.target.labels[0].innerText })
        } else {
            if(name === "phoneNumber" || name === "realtor_phoneNumber") {
                var x = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                const filterValue = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
                setSecondData({ ...secondData, [name]: filterValue })

            } else {
                setSecondData({ ...secondData, [name]: value })
            }

        }
        setIsAnyError(Object.values(error).some(item => item !== ""))
    }

    const validateAge = (DOB) => {
        const givenDate = new Date(DOB);
        const currentDate = new Date();
        const date18YearsAgo = new Date(
            currentDate.getFullYear() - 18,
            currentDate.getMonth(),
            currentDate.getDate()
        );
        return givenDate <= date18YearsAgo
    }

    const handleNext = async (e) => {
        e.preventDefault()

        if(!isOtpSent) {
            setIsSubmitted(true)
            let error = {};
            let isError = false;

            if(!data.email) {
                error = { ...error, email: "email is required" }
                isError = true
            } else if(!email_pattern.test(data.email)) {
                error = { ...error, email: "email is invaild" }
                isError = true
            }
            if(!data.password) {
                error = { ...error, password: "password is required" }
                isError = true
            } else if(data.password.length < 8) {
                error = { ...error, password: "minimum 8 charcters required" }
                isError = true
            }

            if(isError) {
                setErrors(error)
                return
            }

            setIsMainLoader(true)
            try {
                const res = await Auth.signUp({
                    username: data.email,
                    password: data.password
                });
                if(res.userSub) {
                    toast.success("Verification code sent to your mail.")
                    setIsOtpSent(true)
                    setUserSub(res.userSub)
                    setIsMainLoader(false)
                }
            } catch(error) {
                if(error.code === "UsernameExistsException") {
                    try {
                        const signInRes = await Auth.signIn({
                            username: data.email,
                            password: data.password,
                        });

                        if(signInRes) {
                            localStorage.setItem('accessToken', signInRes.signInUserSession.idToken.jwtToken)
                        }

                        await API.post(REACT_APP_API_NAME, "/user", {
                            body: {
                                cognitoId: signInRes.username,
                                email: data.email
                            },
                            headers: {
                                Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                            }
                        })
                            .then((response) => {
                                localStorage.setItem("userData", JSON.stringify(response.data));
                                setUserData(response.data)
                                setIsMainLoader(false)
                                setIsEmailVerify(true)
                            })
                            .catch((err) => {
                                console.log(err)
                                toast.error(err?.response?.data?.error)
                            })

                    } catch(err) {
                        if(err.code === "UserNotConfirmedException") {
                            try {
                                const reSignInRes = await Auth.resendSignUp(data.email);
                                toast.success("Verification code sent to your mail.")
                                setIsOtpSent(true)
                                setIsMainLoader(false)
                            } catch(er) {
                                toast.error(er.message)
                            }
                        } else {
                            toast.error(err.message)
                        }
                    }
                } else {
                    toast.error(error.message)
                }
                setIsMainLoader(false)
            }
        } else {
            let error = {};
            let isError = false;

            if(!data.otp) {
                error = { ...error, otp: "Verification code is required" }
                isError = true
            }

            if(isError) {
                setErrors(error)
                return
            }

            setIsMainLoader(true)

            try {
                const res = await Auth.confirmSignUp(data.email, data.otp);
                setIsOtpSent(false)
                try {
                    const signInRes = await Auth.signIn({
                        username: data.email,
                        password: data.password,
                    });

                    if(signInRes) {
                        localStorage.setItem('accessToken', signInRes.signInUserSession.idToken.jwtToken)
                    }

                    await API.post(REACT_APP_API_NAME, "/user", {
                        body: {
                            cognitoId: signInRes.username,
                            email: data.email
                        },
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                        }
                    })
                        .then((response) => {
                            localStorage.setItem("userData", JSON.stringify(response.data));
                            setUserData(response.data)
                            setIsMainLoader(false)
                            setIsEmailVerify(true)
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                } catch(err) {
                    toast.error(err.message)
                    setIsMainLoader(false)
                }
            } catch(error) {
                toast.error(error.message)
                setIsMainLoader(false)
            }

        }
    }

    const handleNextStep = async (e) => {
        e.preventDefault()
        setIsSecondSubmitted(true)
        let error = {};
        let isError = false;

        if(!secondData.firstName) {
            error = { ...error, firstName: "First name is required" }
            isError = true
        }
        if(!secondData.lastName) {
            error = { ...error, lastName: "Last name is required" }
            isError = true
        }
        if(!secondData.drivers_license) {
            error = { ...error, drivers_license: "Drivers license is required" }
            isError = true
        }
        if(!secondData.ssn) {
            error = { ...error, ssn: "SSN is required" }
            isError = true
        }
        if(!secondData.phoneNumber) {
            error = { ...error, phoneNumber: "Phone number is required" }
            isError = true
        } else if(!phone_pattern.test(secondData.phoneNumber)) {
            error = { ...error, phoneNumber: "Phone number is invaild" }
            isError = true
        }
        if(!secondData.dateOfBirth) {
            error = { ...error, dateOfBirth: "Date of birth is required" }
            isError = true
        } else if(!validateAge(secondData.dateOfBirth)) {
            error = { ...error, dateOfBirth: "Must be 18 year old" }
            isError = true
        }

        if(secondData.realtor_worker === "Yes") {
            if(!secondData.realtor_name) {
                error = { ...error, realtor_name: "Realtor name is required" }
                isError = true
            }
            if(!secondData.realtor_phoneNumber) {
                error = { ...error, realtor_phoneNumber: "Realtor phone number is required" }
                isError = true
            } else if(!phone_pattern.test(secondData.realtor_phoneNumber)) {
                error = { ...error, realtor_phoneNumber: "Realtor phone number is invaild" }
                isError = true
            }
            if(!secondData.realtor_email) {
                error = { ...error, realtor_email: "Realtor email is required" }
                isError = true
            } else if(!email_pattern.test(secondData.realtor_email)) {
                error = { ...error, realtor_email: "Realtor email is invaild" }
                isError = true
                if(secondData.realtor_email === "N/A" || secondData.realtor_email === "n/a" || secondData.realtor_email === "N/a" || secondData.realtor_email === "n/A"){
                    error = { ...error, realtor_email: "" }
                    isError = false
                }
            }
        }

        if(isError) {
            setSecondErrors(error)
            setIsAnyError(true)
            return
        }
        let mainData;
        if(secondData.realtor_worker === "Yes") {
            mainData = {
                email: data.email,
                applying_as: secondData.applying_as,
                realtor_worker: secondData.realtor_worker,
                firstName: secondData.firstName,
                lastName: secondData.lastName,
                drivers_license: secondData.drivers_license,
                ssn: secondData.ssn,
                phoneNumber: secondData.phoneNumber,
                dateOfBirth: secondData.dateOfBirth,
                realtor_name: secondData.realtor_name,
                realtor_phoneNumber: secondData.realtor_phoneNumber,
                realtor_email: secondData.realtor_email,
            }
        } else {
            mainData = {
                email: data.email,
                applying_as: secondData.applying_as,
                realtor_worker: secondData.realtor_worker,
                firstName: secondData.firstName,
                lastName: secondData.lastName,
                drivers_license: secondData.drivers_license,
                ssn: secondData.ssn,
                phoneNumber: secondData.phoneNumber,
                dateOfBirth: secondData.dateOfBirth,
            }
        }

        setIsMainLoader(true)
        let isUpdateStep = await setMainData(1, mainData)
        if(!isUpdateStep){
            setActiveStep(2)
        }
    }

    return (
        <>
            <section className='step-section step-1'>
                <p><b>Property: &nbsp;</b> {localStorage.getItem('propertyName')}</p>
                <h3 className='step-title mb-3'>About You</h3>
                <ul className='about-list'>
                    {/* <li>Get updates on your application</li>
                    <li>Message the landlord directly</li> */}
                    <li>Save your application & finish at any time</li>
                </ul>
                {/* {!isUser &&
                    <div className='d-sm-flex sign-up-page border-bottom'>
                        <LoginWithGoogle text="Sign up with Google" />
                        <LoginWithFacebook text="Continue with Facebook" />
                    </div>
                } */}
                {!isUser ?
                    <Form onSubmit={handleNext}>
                        <Row className="mt-4">
                            <Form.Group as={Col} md="8" className='mb-3' controlId="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    value={data.email}
                                    onChange={handleChange}
                                    isInvalid={errors.email}
                                    disabled={isOtpSent}
                                />
                                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="8" className='mb-3' controlId="password">
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    name="password"
                                    value={data.password}
                                    onChange={handleChange}
                                    isInvalid={errors.password}
                                    disabled={isOtpSent}
                                />
                                <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                            </Form.Group>
                            {isOtpSent && <Form.Group as={Col} md="6" className='mb-3' controlId="otp">
                                <Form.Label>Verification code</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="otp"
                                    value={data.otp}
                                    onChange={handleChange}
                                    isInvalid={errors.otp}
                                />
                                <Form.Control.Feedback type="invalid">{errors.otp}</Form.Control.Feedback>
                            </Form.Group>}
                        </Row>
                        <p onClick={() => setLoginModal(true)} className="text-decoration-underline link-text pointer">I already have an account</p>
                        <div className='my-4'></div>
                        <Button type="submit" disabled={isMainLoader} className='text-uppercase step-button d-block mx-auto mt-3'>{isMainLoader ? <Loader /> : 'Next'}</Button>
                    </Form>
                    :
                    <Form onSubmit={handleNextStep}>
                        <Row className="mt-3">
                            <Form.Group as={Col} md="6" className='mb-3'>
                                <Form.Label className={!isUser ? '' : 'mt-4'}>What are you applying as?</Form.Label>
                                <div className="mb-2 tab-style">
                                    <Form.Check
                                        label="Tenant"
                                        name="applying_as"
                                        type="radio"
                                        id={`applying_as_1`}
                                        checked={secondData.applying_as === 'Tenant'}
                                        onChange={secondHandleChange}
                                    />
                                    <Form.Check
                                        label="Co-Signer"
                                        name="applying_as"
                                        type="radio"
                                        id={`applying_as_2`}
                                        checked={secondData.applying_as === 'Co-Signer'}
                                        onChange={secondHandleChange}
                                    />
                                </div>
                            </Form.Group>
                            <Form.Group as={Col} md="6" className='mb-3'>
                                <Form.Label className={!isUser ? '' : 'mt-4'}>Are you working with a realtor?</Form.Label>
                                <div className="mb-2 tab-style">
                                    <Form.Check
                                        label="Yes"
                                        name="realtor_worker"
                                        type="radio"
                                        id={`is_realtor_worker_1`}
                                        checked={secondData.realtor_worker === 'Yes'}
                                        onChange={(event) => secondHandleChange(event, "Yes")}
                                    />
                                    <Form.Check
                                        label="No"
                                        name="realtor_worker"
                                        type="radio"
                                        id={`is_realtor_worker_2`}
                                        checked={secondData?.realtor_worker === 'No'}
                                        onChange={(event) => secondHandleChange(event, "No")}
                                    />
                                </div>
                            </Form.Group>
                        </Row>
                        {
                            secondData.realtor_worker === "Yes" &&
                            <Row>
                                <Form.Group as={Col} md="6" className='mb-3' controlId="realtor_name">
                                    <Form.Label>Realtor Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="realtor_name"
                                        value={secondData.realtor_name}
                                        onChange={secondHandleChange}
                                        isInvalid={secondErrors.realtor_name}
                                    />
                                    <Form.Control.Feedback type="invalid">{secondErrors.realtor_name}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="6" className='mb-3' controlId="realtor_phoneNumber">
                                    <Form.Label>Realtor  Phone Number</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="realtor_phoneNumber"
                                        value={secondData.realtor_phoneNumber}
                                        placeholder="(---) --- - ----"
                                        onChange={secondHandleChange}
                                        isInvalid={secondErrors.realtor_phoneNumber}
                                    />
                                    <Form.Control.Feedback type="invalid">{secondErrors.realtor_phoneNumber}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="12" className='mb-3' controlId="realtor_email">
                                    <Form.Label>Realtor Email - Enter N/A if Unknown</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="realtor_email"
                                        value={secondData.realtor_email}
                                        onChange={secondHandleChange}
                                        isInvalid={secondErrors.realtor_email}
                                    />
                                    <Form.Control.Feedback type="invalid">{secondErrors.realtor_email}</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                        }
                        <Row >
                            <div className='col-12 mt-1'>
                                <h3 className='step-title mb-3'>Applicant Information</h3>
                            </div>
                            <Form.Group as={Col} md="6" className='mb-3' controlId="firstName">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="firstName"
                                    value={secondData.firstName}
                                    onChange={secondHandleChange}
                                    isInvalid={secondErrors.firstName}
                                />
                                <Form.Control.Feedback type="invalid">{secondErrors.firstName}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="6" className='mb-3' controlId="lastName">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="lastName"
                                    value={secondData.lastName}
                                    onChange={secondHandleChange}
                                    isInvalid={secondErrors.lastName}
                                />
                                <Form.Control.Feedback type="invalid">{secondErrors.lastName}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="6" className='mb-3' controlId="phoneNumber">
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="phoneNumber"
                                    placeholder="(---) --- - ----"
                                    value={secondData.phoneNumber}
                                    onChange={secondHandleChange}
                                    isInvalid={secondErrors.phoneNumber}
                                />
                                <Form.Control.Feedback type="invalid">{secondErrors.phoneNumber}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="6" className='mb-3' controlId="dateOfBirth">
                                <Form.Label>Date of Birth</Form.Label>
                                <DatePicker
                                    value={secondData.dateOfBirth}
                                    onChange={newDate => {
                                        secondHandleChange({ target: { name: 'dateOfBirth', value: newDate } });
                                    }}
                                    maxDate={new Date()}
                                    className={secondErrors.dateOfBirth ? "date-picker-error" : ""}
                                />
                                {secondErrors.dateOfBirth && <div className="text-danger error-msg">{secondErrors.dateOfBirth}</div>}
                            </Form.Group>
                            <Form.Group as={Col} md="6" className='mb-3' controlId="drivers_license">
                                <Form.Label>Driver's License or State ID #</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="drivers_license"
                                    value={secondData.drivers_license}
                                    onChange={secondHandleChange}
                                    isInvalid={secondErrors.drivers_license}
                                />
                                <Form.Control.Feedback type="invalid">{secondErrors.drivers_license}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="6" className='mb-3' controlId="ssn">
                                <Form.Label>SSN</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="ssn"
                                    value={secondData.ssn}
                                    onChange={secondHandleChange}
                                    isInvalid={secondErrors.ssn}
                                />
                                <Form.Control.Feedback type="invalid">{secondErrors.ssn}</Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Button type="submit" disabled={isMainLoader} className='text-uppercase step-button d-block mx-auto mt-3'>{isMainLoader ? <Loader /> : 'Next'}</Button>
                        {isAnyError && <p className="text-danger error-msg m-2 text-center">All fields not completed</p>}
                    </Form>}
            </section>
        </>
    )
}

export default StepOne